import HamburgerMenu from './HamburgerMenu';
import Dropdown from './Dropdown';

let currentScroll = window.scrollY;
let initialScroll = true;

function throttle(callback, interval = 0) {
    let throttled = true;

    // eslint-disable-next-line func-names
    return function (...args) {
        if (!throttled) {
            return;
        }

        throttled = false;
        // @ts-ignore
        callback.apply(this, args);
        if (interval > 0) {
            // eslint-disable-next-line no-return-assign
            setTimeout(() => throttled = true, interval);
        } else {
            // eslint-disable-next-line no-return-assign
            window.requestAnimationFrame(() => throttled = true);
        }
    };
}

function updateNavigation() {
    if (window.innerWidth > 900) {
        hamburgerMenu!.updateAria(true);
        document
            .querySelector('.site-header')!
            .querySelectorAll('.has-children')
            .forEach((element) => dropdowns.push(new Dropdown(element)));
    } else {
        hamburgerMenu!.updateAria(hamburgerMenu!.isMenuOpen());
        dropdowns.forEach(dropdown => dropdown.destroy())
        dropdowns = []
    }
}

let dropdowns: Dropdown[] = []
let hamburgerMenu:null|HamburgerMenu = null;

const navigation = document.querySelector('.site-header__container');

if (navigation) {
    hamburgerMenu = new HamburgerMenu(navigation);
}

let timer;

window.addEventListener(
    'resize',
    (event) => {
        clearTimeout(timer);

        timer = setTimeout(updateNavigation, 250);
    },
)

updateNavigation();
