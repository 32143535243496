class HamburgerMenu
{
    protected buttonElement: Element;
    protected containerElement: Element;
    protected hamburgerElement: null|Element;
    protected logoElement: null|Element;
    protected menuElement: null|Element;

    constructor(containerElement: Element) {
        this.buttonElement = document.createElement('button');
        this.containerElement = containerElement;
        this.hamburgerElement = containerElement.querySelector(
            '.site-header__hamburger',
        );
        this.logoElement = containerElement.querySelector(
            '.site-header__logo a',
        );
        this.menuElement = containerElement.querySelector(
            '.site-header__navigation',
        );

        this.createButton();
        this.addEventListeners();
    }

    public isMenuOpen() {
        return document.documentElement.classList.contains('html--show-navigation');
    }

    public updateAria(isNavigationActive: boolean) {
        if (isNavigationActive) {
            this.buttonElement.setAttribute('aria-expanded', 'true');
            this.menuElement?.setAttribute('aria-hidden', 'false');
            this.logoElement?.setAttribute('tabindex', '0');
        } else {
            this.buttonElement.setAttribute('aria-expanded', 'false');
            this.menuElement?.setAttribute('aria-hidden', 'true');
            this.logoElement?.removeAttribute('tabindex');
        }
    }

    private closeMenu() {
        document.documentElement.classList.remove('html--show-navigation');
        this.updateAria(false);
    }

    private openMenu() {
        document.documentElement.classList.add('html--show-navigation');
        this.updateAria(true);
    }

    private addEventListeners() {
        document.addEventListener(
            'click',
            (event) => {
                // @ts-ignore
                if (event.target.closest('.site-header__hamburger') === null) {
                    return;
                }

                event.preventDefault();

                if (this.isMenuOpen()) {
                    this.closeMenu();
                } else {
                    this.openMenu();
                }
            },
        );

        document.addEventListener(
            'keydown',
            (event) => {
                const focusableElements = this.containerElement.querySelectorAll(
                    '[href]:not(.site-header__title a), input[type="submit"], button',
                );

                if (!this.isMenuOpen()) {
                    return;
                }

                if (event.key === 'Tab') {
                    if ( event.shiftKey ) {
                        if (document.activeElement === focusableElements[0]) {
                            event.preventDefault();
                            (focusableElements[focusableElements.length -1] as HTMLElement).focus();
                        }
                    } else {
                        if (document.activeElement === focusableElements[focusableElements.length - 1]) {
                            event.preventDefault();
                            (focusableElements[0] as HTMLElement).focus();
                        }
                    }
                } else if (event.key === 'Escape' || event.keyCode === 27) {
                    event.preventDefault();
                    this.closeMenu();
                    (this.buttonElement as HTMLElement).focus();
                }
            }
        );
    }

    private createButton() {
        let attr;
        const hamburgerAttributes = Array.prototype.slice.call(
            this.hamburgerElement?.attributes,
        );

        this.buttonElement.innerHTML =
            this.hamburgerElement?.innerHTML.trim() ?? '';

        while (attr = hamburgerAttributes.pop()) {
            this.buttonElement.setAttribute(attr.nodeName, attr.nodeValue);
        }

        this.buttonElement.setAttribute('aria-controls', 'hamburger');
        this.menuElement?.setAttribute('id', 'hamburger');

        // @ts-ignore
        this.containerElement.replaceChild(this.buttonElement, this.hamburgerElement);
    }
}

export default HamburgerMenu;
